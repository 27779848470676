import { useCallback, useEffect, useState } from "react";
import { client } from "../lib/axiosClient";
import Cookies from 'universal-cookie';

const cookies = new Cookies(null, { path: '/' });


export const useRefreshTokenUser = () => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);

    const fetchData = useCallback(async (loading: boolean) => {
        if (loading) {
            setLoading(true);
        }
        try {
            const response = await client.get(`/refresh-users`, {
                headers: {
                    'Accept': 'application/json',
                    // 'Authorization': cookies.get('token') || ""
                }
            });
            
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            setData(response?.data)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, []);
    
    // useEffect(() => {
    //     fetchData();
    // }, [fetchData]);
    
    const refetch = useCallback((loading: boolean) => {
        console.log("refetch");
        
        fetchData(loading);
    }, [fetchData]);

      
    return { data, loading, error, fetchData, refetch };
}