import axios from 'axios';
import Cookies from 'universal-cookie';
import { redirect } from 'react-router-dom';

const cookies = new Cookies(null, { path: '/' });

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Accept': 'application/json',
    'Authorization': cookies.get('token') || ""
  },
  withCredentials: true
});

client.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      console.log(error.response);
      
      // cookies.remove('token');
      // window.location.href = '/';
    }
    return Promise.reject(error);
  }
)

export {
  client
}