import { AppShell, Burger, Loader, Stack } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, redirect } from 'react-router-dom';
import { Navbar, Sidebar } from '../../componants';
import Cookies from 'universal-cookie';
import SocketClient from '../../lib/socket';
import { useRefreshTokenUser } from '../../api';

const cookies = new Cookies(null, { path: '/' });

export function Layout () {
    const [opened, { toggle }] = useDisclosure();
    const [sideBarOpened, { toggle: toggleSideBar }] = useDisclosure(false);
    const navigate = useNavigate();
    const location = useLocation();
    const matches = useMediaQuery('(max-width: 48em)');
    const {loading, error, data: dataProducts, fetchData, refetch} = useRefreshTokenUser()

    // useEffect(() => {
    //     if (!cookies.get('token') || cookies.get('token') === "") {
    //         navigate("/");
    //     }
    // }, [cookies.get('token')])

    useEffect(() => {
        fetchData(true);
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            refetch(false);
        }, 1000 * 60 * 4)
        return () => clearInterval(intervalId);
    }, [])

    useEffect(() => {
        let socketIo = new SocketClient({
            idUser: cookies.get('id')
        })
        socketIo.connect();

        return () => {
            if (socketIo != null) {
                socketIo.disconnect();
            }
        };
    }, [])

    
    useEffect(() => {
        if (matches) {
            toggleSideBar()
        }
    }, [location])
    
    if (loading) {
        return <Stack h={"100vh"} w={"100vw"} justify='center' align='center'>
            <Loader color="yellow" size="xl" type="bars" />
        </Stack>
    }

    if(!cookies.get('token') || cookies.get('token') === "") {
        return <></>
    }

    return (
        <AppShell
            header={{ height: 60 }}
            padding={{ base: "xs", sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' }}
            navbar={{ 
                width: {base: "100%", xs: "100%", sm: 220, md: 220, lg: 220, xl: 220},
                breakpoint: 'xs',
                collapsed: { mobile: !sideBarOpened, desktop: sideBarOpened }
            }}
        >
            <AppShell.Header>
                <Navbar toggleSideBar={toggleSideBar} sideBarOpened={sideBarOpened} />
            </AppShell.Header>
    
            <AppShell.Navbar >
                <Sidebar/>
            </AppShell.Navbar>

            <AppShell.Main bg={"gray.1"} pt={90}>
                <Outlet />
            </AppShell.Main>
        </AppShell>
    );
}